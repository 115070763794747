import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

const ENV_PROTOCOL = 'https';
const CLASSLINK_CLIENT_ID = 'c16578182785427792f357925832378d9cb7cad9ca77';
const URL = 'beta-api.plasma.games';

export const environment = {
  name: 'Beta',
  production: false,
  DOMAIN_WILDCARD: '.plasma.games',
  API_URL: `https://${URL}/graphql`,
  SCIOPS_API: `https://${URL}/`,
  plugins: [NgxsReduxDevtoolsPluginModule.forRoot(), NgxsLoggerPluginModule.forRoot()],
  SSO_URL_GOOGLE: `https://${URL}/auth/google/login`,
  SSO_URL_CLEVER: `https://clever.com/oauth/authorize?response_type=code&client_id=b7b2290fcd7268ea925a&redirect_uri=https://${URL}/auth/clever/login`,
  SSO_URL_CLASS_LINK: `https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=${ENV_PROTOCOL}://${URL}/auth/classlink/callback&client_id=${CLASSLINK_CLIENT_ID}&response_type=code`,
  SSO_CLEVER_ENABLED: false,
  GOOGLE_TAG_ID: 'GTM-K8LCKC4F',
  AMPLITUDE_ID: '',
  AMPLITUDE_URL: `https://${URL}/amplitude`,
};
